export default [
  {
    header: 'Oppourtunities',
  },
  {
    title: 'Startup Programs',
    route: 'mentor-programs',
    icon: 'GridIcon',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Events',
    icon: 'ActivityIcon',
    route: 'mentor-events',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Competitions',
    icon: 'ZapIcon',
    route: 'mentor-competitions',
    resource: 'Mentor',
    action: 'read',
  },
  {
    title: 'Skill Development',
    route: 'mentor-skill-dev',
    icon: 'UserIcon',
    resource: 'Mentor',
    action: 'read',
  },
  // {
  //   title: 'Mentor Portal',
  //   icon: 'AwardIcon',
  //   children: [
  //     // {
  //     //   title: 'Invitations',
  //     //   route: 'mentor-invitations',
  //     //   icon: 'UserPlusIcon',
  //     //   resource: 'Mentor',
  //     //   action: 'read',
  //     // },
  //   ],
  // },
]
