export default [
  {
    header: 'Opportunities',
  },
  {
    title: `${JSON.parse(localStorage.getItem('userData')).associatedOrgDetails.length > 0 ? 'Startup' : 'Skill Dev'} Programs`,
    icon: 'GridIcon',
    route: 'startup-portal-program',
  },
  {
    title: 'Events',
    icon: 'ActivityIcon',
    route: 'startup-portal-events',
  },
  {
    title: 'Competitions',
    icon: 'ZapIcon',
    route: 'startup-portal-competition',
  },

]
