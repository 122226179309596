export default [
  {
    header: 'Manage Opportunities',
  },
  {
    title: 'Startup Programs',
    icon: 'GridIcon',
    route: 'manage-programs',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Events',
    icon: 'ActivityIcon',
    route: 'event-details',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Competitions',
    icon: 'ZapIcon',
    route: 'manage-competitions',
    resource: 'Incubator',
    action: 'read',
  },
  {
    title: 'Skill Development',
    icon: 'UserIcon',
    route: 'manage-skill-dev-programs',
    resource: 'Incubator',
    action: 'read',
  },
]
