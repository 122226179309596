function datasetter() {
  if (JSON.parse(localStorage.getItem('userData')).associatedOrgDetails.length === 0) {
    return [
      {
        header: 'Jobs',
      },
      {
        title: 'Full Time',
        icon: 'BriefcaseIcon',
        route: 'student-portal-full-time',
      },
      {
        title: 'Internships',
        icon: 'TrendingUpIcon',
        route: 'student-portal-internships',
      },
      {
        title: 'Live Projects',
        icon: 'GitCommitIcon',
        route: 'student-portal-live-projects',
      },
    ]
  }
  return []
}
export default datasetter()
