function datasetter() {
  if (JSON.parse(localStorage.getItem('userData')).associatedOrgDetails.length > 0) {
    return [
      {
        header: 'Performance',
      },
      {
        title: 'Business Details',
        icon: 'BriefcaseIcon',
        route: 'startup-portal-business-details',
      },
      {
        title: 'Milestones',
        icon: 'TrendingUpIcon',
        route: 'startup-portal-milestones',
      },
      {
        title: 'Growth',
        icon: 'GitCommitIcon',
        route: 'startup-portal-growth',
      },
    ]
  }
  return []
}
export default datasetter()
