<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotifications.length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{ unreadNotifications.length }} New
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      :settings="{ maxScrollbarLength: 60, wheelPropagation: false }"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in formattedNotifications"
        :key="notification.notifications_log_id"
        :to="{
          name: notification.template_details.link_route_name,
          params: notification.link_route_params,
        }"
      >
        <b-media
          :class="{ 'bg-light': notification.status !== 'unread' }"
        >
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <div class="d-flex justify-content-between">
            <div>
              <p class="media-heading">
                <span class="font-weight-bolder">{{ notification.title }}</span>
              </p>
              <small class="notification-text">{{ $moment(notification.created_at).fromNow() }}</small>
            </div>
            <b-button
              v-if="notification.status === 'unread'"
              size="sm"
              variant="flat-secondary"
              @click="updateNotificationStatus({ id: notification.notifications_log_id, status: 'read' })"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </b-media>
      </b-link>
      <!-- Load More -->
      <div
        v-if="hasMoreNotifications"
        class="px-1 py-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          size="sm"
          variant="primary"
          @click="loadOlderNotifications"
        >
          Load more
        </b-button>
      </div>
      <p
        v-else
        class="p-1 text-center mb-0"
      >
        Hooray! You have seen it all 🎉
      </p>
    </vue-perfect-scrollbar>

    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-primary"
        block
        @click="readAllNotifications"
      >
        Read all notifications
      </b-button>
      <!--      <b-button-->
      <!--        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
      <!--        block-->
      <!--        variant="primary"-->
      <!--        @click="createTestNotification"-->
      <!--      >Test-->
      <!--      </b-button>-->
      <!--      Button to load more notifications-->

    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import Mustache from 'mustache'

export default {
  components: {
    BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, VuePerfectScrollbar, BButton,
  },
  directives: { Ripple },
  data() {
    return {
      notifications: [],
      hasMoreNotifications: true,
      perfectScrollbarSettings: { maxScrollbarLength: 60, wheelPropagation: false },
    }
  },
  computed: {
    formattedNotifications() {
      const icons = {
        success: 'CheckIcon', error: 'XCircleIcon', warning: 'AlertTriangleIcon', info: 'InfoIcon',
      }
      return this.notifications.map(notification => ({
        ...notification,
        title: Mustache.render(notification.template_details.content, notification.variables),
        type: notification.template_details?.type || 'info',
        icon: icons[notification.template_details?.type] || 'InfoIcon',
        link_route_name: notification.template_details.link_route_name,
      }))
    },
    unreadNotifications() {
      if (!this.notifications) return []
      return this.notifications.filter(notification => notification.status === 'unread')
    },
  },
  methods: {
    createTestNotification() {
      const currentEntity = this.getSelectedEntity()
      // Prompt user for test value
      const testValue = prompt('Enter test value')
      this.createNotification({
        templateName: 'test_all',
        variables: { test_variable: testValue },
        toUserId: currentEntity.user_id,
        toOrgId: currentEntity.org_id,
      })
    },
    updateNotificationStatus({ id, status }) {
      this.$apollo.mutate({
        mutation: gql`
        mutation updateNotificationStatus($id: uuid!, $status: String!) {
          update_notifications_log_table(
            where: { notifications_log_id: { _eq: $id } },
            _set: { status: $status }
          ) {
            affected_rows
          }
        }
      `,
        variables: { id, status },
        update: (store, { data: { update_notifications_log_table } }) => {
          if (update_notifications_log_table.affected_rows > 0) {
            this.notifications.find(notification => notification.notifications_log_id === id).status = status
          }
        },
      })
    },
    readAllNotifications() {
      this.notifications.forEach(notification => {
        if (notification.status === 'unread') {
          this.updateNotificationStatus({ id: notification.notifications_log_id, status: 'read' })
        }
      })
    },
    loadOlderNotifications() {
      // Load older notifications
      this.$apollo.queries.notifications.fetchMore({
        variables: {
          limit: 5,
          offset: this.notifications.length,
          userId: this.getSelectedEntity().user_id || 0,
          orgId: this.getSelectedEntity().org_id || 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult
          this.hasMoreNotifications = fetchMoreResult.notifications_log_table.length > 0
          return {
            ...previousResult,
            notifications_log_table: [
              ...previousResult.notifications_log_table,
              ...fetchMoreResult.notifications_log_table,
            ],
          }
        },
      })
    },
  },
  apollo: {
    notifications: {
      query: gql`
    query notifications($userId: Int!, $orgId: Int!, $limit: Int = 5, $offset: Int = 0) {
      notifications_log_table(
        limit: $limit,
        offset: $offset,
        order_by: { created_at: desc },
        where: {
          _or: [
            { to_user_id: { _eq: $userId } },
            { to_org_id: { _eq: $orgId } }
          ]
        }
      ) {
        notifications_log_id
        template_details { content type link_route_name }
        variables
        created_at
        link_route_params
        status
      }
    }
  `,
      variables() {
        const entity = this.getSelectedEntity()
        return { userId: entity.user_id || 0, orgId: entity.org_id || 0 }
      },
      update: data => data.notifications_log_table,
      subscribeToMore: {
        document: gql`
      subscription notifications($userId: Int!, $orgId: Int!) {
        notifications_log_table(
          limit: 5,
          order_by: { created_at: desc },
          where: {
            _or: [
              { to_user_id: { _eq: $userId } },
              { to_org_id: { _eq: $orgId } }
            ]
          }
        ) {
          notifications_log_id
          template_details { content type link_route_name }
          variables
          created_at
          link_route_params
          status
        }
      }
    `,
        variables() {
          const entity = this.getSelectedEntity()
          return { userId: entity.user_id || 0, orgId: entity.org_id || 0 }
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev

          const newNotifications = subscriptionData.data.notifications_log_table
          const existingNotifications = prev.notifications_log_table

          // Filter out any new notifications that already exist in the existing notifications
          const uniqueNewNotifications = newNotifications.filter(
            newNotification => !existingNotifications.some(
              existingNotification => existingNotification.notifications_log_id === newNotification.notifications_log_id,
            ),
          )

          return {
            ...prev,
            notifications_log_table: [
              ...uniqueNewNotifications,
              ...existingNotifications,
            ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at)), // Ensure the list is sorted by created_at in descending order
          }
        },
      },
    },
  },
}
</script>
