/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
// import resources from './resources'
// import events from './events'
// import teams from './teams'
// import tickets from './tickets'
// import finances from './finances'
// import fundings from './fundings'
import diligence from './duediligence'
import startups from './startups'
import mentors from './mentors'
import partners from './partners'
import students from './students'
// import campaigns from './campaigns'
import programs from './programs'
// import startupPortal from './startup-portal'
// import studentPortal from './student-portal'
// import partnerPortal from './partner-portal'
import mentorPortal from './mentor-portal'
import mentorPortalOpportunities from './mentor-portal-opportunities'
import opportunities from './opportunities'
import performance from './performance'
import jobs from './jobs'

// Array of sections
// export default [...dashboard, ...programs, ...diligence, ...fundings, ...events, ...startups, ...mentors, ...teams, ...finances, ...resources, ...tickets, ...startup, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
export default [
  ...dashboard,
  ...programs.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  ...diligence.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  ...startups.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  ...mentors.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  ...partners.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  ...students.map(e => Object.assign(e, {
    resource: 'Incubator',
    action: 'read',
  })),
  // ...fundings.map(e => Object.assign(e, {
  //   resource: 'Incubator',
  //   action: 'read',
  // })),
  // ...finances.map(e => Object.assign(e, {
  //   resource: 'Incubator',
  //   action: 'read',
  // })),
  // ...campaigns.map(e => Object.assign(e, {
  //   resource: 'Incubator',
  //   action: 'read',
  // })),
  ...opportunities.map(e => Object.assign(e, {
    resource: 'User',
    action: 'read',
  })),
  ...performance.map(e => Object.assign(e, {
    resource: 'User',
    action: 'read',
  })),
  ...jobs.map(e => Object.assign(e, {
    resource: 'User',
    action: 'read',
  })),
  // ...startupPortal.map(e => Object.assign(e, {
  //   resource: 'User',
  //   action: 'read',
  // })),
  // ...studentPortal.map(e => Object.assign(e, {
  //   resource: 'User',
  //   action: 'read',
  // })),
  ...mentorPortalOpportunities.map(e => Object.assign(e, {
    resource: 'Mentor',
    action: 'read',
  })),
  ...mentorPortal.map(e => Object.assign(e, {
    resource: 'Mentor',
    action: 'read',
  })),
  // ...partnerPortal.map(e => Object.assign(e, {
  //   resource: 'Mentor',
  //   action: 'read',
  // })),

]
